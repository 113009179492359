<template>
     <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
     <WhatsAppButton></WhatsAppButton>
     <div class="locationMainAllDiv">
            <div class="container">
            <div class="locationOutWrapperMain">
                <h3>Servicable Locations</h3>
                <ul class="location_list">
                    <template v-for="(location,index) in locationList" :key="index">
                        <li @click="setLocation(location.area,location)"><a href="javascript:void(0)" >{{location.area}}</a></li>
                    </template>
                    
                </ul>

            </div>   
           </div> 
        </div> 
</template>
<script>
import {  mapActions } from "vuex";
import Breadcrumb from "../components/Breadcrumb";
import WhatsAppButton from "@/components/WhatsAppButton";
import $ from 'jquery';
export default {
    name:'LocationList',
    methods:{
        ...mapActions(['getPincodeList']),
          setLocation(loc,selectedLocation){
          if(loc!=''){              
                this.$cookies.set('location_title',loc);
                this.$cookies.set('location_info',selectedLocation);
                localStorage.setItem('location_info',JSON.stringify(selectedLocation));
               
                window.location='/';
          }
         
         
         
        }
    },
  
    data(){
            return{
                locationList:[],
                  urls: [
                    {
                    path: "/",
                    name: "Home",
                    },
                    {
                    path: "/locations",
                    name: "Locations",
                    },
                    
                ],
            }
    },
     components: {
        Breadcrumb,
        WhatsAppButton 
     },
    mounted(){
        let $this= this;
        this.getPincodeList().then(function(response){
            $this.locationList = response.data.Data;
        });

        $('#product-quickview').removeClass('show');
        $('#product-quickview').css({'display':'none'});
        $('.ps-site-overlay').css({'visibility':'hidden','opacity':' 0'});
        $('.modal-backdrop').remove();
    }
}
</script>
<style scoped>
    .locationOutWrapperMain{
        margin-top:30px;
        margin-bottom:30px
    }
    .location_list{margin:0; padding: 0px;}
    .location_list li{ display: inline-block; width: 32%;}

    .locationMainAllDiv{
        min-height: 300px;
    }

   .location_list  li{
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-right: 16px;
        margin-bottom:10px;
        cursor: pointer;
    }
     .location_list  li:hover{
       background: #D71920;
       color: #fff;
    }
</style>